import React from "react";
import styled from "styled-components";
import type { ProductSliderDataItem } from ".";
import ProductSlider from "../../../product/components/ProductSlider";
import { useProductTilesByIds } from "../../../product/hooks/useProductTilesByIds";
import { pluginBaseStyle } from "../../utils/pluginBaseStyle";

const Base = styled.div`
  ${pluginBaseStyle};
`;

const Renderer: React.FC<{ data: ProductSliderDataItem }> = ({
  data: { title, productIds, link },
}) => {
  const products = useProductTilesByIds(productIds)?.data;

  return (
    <Base>
      <ProductSlider
        title={title}
        backgroundColor="sand"
        products={products}
        maxProductCount={productIds?.length || 4}
        link={link}
      />
    </Base>
  );
};
export default Renderer;
