import { gql, useQuery } from "@apollo/client";
import { BaseProductTileParts } from "../queries";
import type {
  GetProductTilesByIds,
  GetProductTilesByIdsVariables,
  GetProductTilesByIds_vpsProductsById,
  GetProductTilesByIds_vpsProductsById_ShopConfigurableProduct,
} from "./types/GetProductTilesByIds";

const QUERY = gql`
  query GetProductTilesByIds($productIds: [ID!]!) {
    vpsProductsById(productIds: $productIds) {
      ...BaseProductTileParts
    }
  }
  ${BaseProductTileParts}
`;

const isConfigurableProduct = (
  product: GetProductTilesByIds_vpsProductsById,
): product is GetProductTilesByIds_vpsProductsById_ShopConfigurableProduct =>
  product?.__typename === "ShopConfigurableProduct";

export const useProductTilesByIds = (productIdsRaw: string[]) => {
  const productIds = productIdsRaw?.map((p) => p.split(".")[0]) ?? []; // <--- we also accept variant id, but currently ignore the suffix
  const { data, ...rest } = useQuery<
    GetProductTilesByIds,
    GetProductTilesByIdsVariables
  >(QUERY, {
    variables: { productIds },
  });

  return {
    data: data?.vpsProductsById
      ?.filter(isConfigurableProduct)
      ?.sort((a, b) => productIds.indexOf(a._id) - productIds.indexOf(b._id)), // careful, sort mutates, its fine here as filter returns a new array
    ...rest,
  };
};
